/*
 * Legal Disclaimer
 * These web fonts are licensed exclusively for use on the following:
 * Domains belonging to Johnson&Johnson
 *
 * It is strictly forbidden to download or use these fonts on any other website domain or media.
 *
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Lineto.com, 2018
 */

/*
 *
 * INSTRUCTIONS
 *
 * Copy the Legal Disclaimer and the @font-faces statements to your regular CSS file.
 * The fonts folder(s) should be placed relative to the regular CSS file.
 *
 */
 @font-face {
  font-family: 'JohnsonDisplay-Bold';
  src: url('common/assets/fonts/JohnsonDisplay-Bold.otf');
  src: url('common/assets/fonts/JohnsonDisplay-Bold.otf') format('otf'),
    url('common/assets/fonts/JohnsonDisplay-Bold.otf') format('otf');
}

@font-face {
  font-family: 'JohnsonDisplay-Light';
  src: url('common/assets/fonts/JohnsonDisplay-Light.otf');
  src: url('common/assets/fonts/JohnsonDisplay-Light.otf') format('otf'),
    url('common/assets/fonts/JohnsonDisplay-Light.otf') format('otf');
}

@font-face {
  font-family: 'JohnsonDisplay-Medium';
  src: url('common/assets/fonts/JohnsonDisplay-Medium.otf');
  src: url('common/assets/fonts/JohnsonDisplay-Medium.otf') format('otf'),
    url('common/assets/fonts/JohnsonDisplay-Medium.otf') format('otf');
}

@font-face {
  font-family: 'JohnsonText-Bold';
  src: url('common/assets/fonts/JohnsonText-Bold.otf');
  src: url('common/assets/fonts/JohnsonText-Bold.otf') format('otf'),
    url('common/assets/fonts/JohnsonText-Bold.otf') format('otf');
}

@font-face {
  font-family: 'JohnsonText-Light';
  src: url('common/assets/fonts/JohnsonText-Light.otf');
  src: url('common/assets/fonts/JohnsonText-Light.otf') format('otf'),
    url('common/assets/fonts/JohnsonText-Light.otf') format('otf');
}

@font-face {
  font-family: 'JohnsonText-Medium';
  src: url('common/assets/fonts/JohnsonText-Medium.otf');
  src: url('common/assets/fonts/JohnsonText-Medium.otf') format('otf'),
    url('common/assets/fonts/JohnsonText-Medium.otf') format('otf');
}

@font-face {
  font-family: 'JohnsonText-Regular';
  src: url('common/assets/fonts/JohnsonText-Regular.otf');
  src: url('common/assets/fonts/JohnsonText-Regular.otf') format('otf'),
    url('common/assets/fonts/JohnsonText-Regular.otf') format('otf');
}

@font-face {
  font-family: 'J&J CircularTT Black Italic Web';
  src: url('common/assets/fonts/J&J CircularTT-BlackItalic.eot');
  src: url('common/assets/fonts/J&J CircularTT-BlackItalic.woff2') format('woff2'),
    url('common/assets/fonts/J&J CircularTT-BlackItalic.woff') format('woff');
}

@font-face {
  font-family: 'J&J CircularTT Bold Web';
  src: url('common/assets/fonts/J&J CircularTT-Bold.eot');
  src: url('common/assets/fonts/J&J CircularTT-Bold.woff2') format('woff2'),
    url('common/assets/fonts/J&J CircularTT-Bold.woff') format('woff');
}

@font-face {
  font-family: 'J&J CircularTT Bold Italic Web';
  src: url('common/assets/fonts/J&J CircularTT-BoldItalic.eot');
  src: url('common/assets/fonts/J&J CircularTT-BoldItalic.woff2') format('woff2'),
    url('common/assets/fonts/J&J CircularTT-BoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'J&J CircularTT Book Web';
  src: url('common/assets/fonts/J&J CircularTT-Book.eot');
  src: url('common/assets/fonts/J&J CircularTT-Book.woff2') format('woff2'),
    url('common/assets/fonts/J&J CircularTT-Book.woff') format('woff');
}

@font-face {
  font-family: 'J&J CircularTT Book Italic Web';
  src: url('common/assets/fonts/J&J CircularTT-BookItalic.eot');
  src: url('common/assets/fonts/J&J CircularTT-BookItalic.woff2') format('woff2'),
    url('common/assets/fonts/J&J CircularTT-BookItalic.woff') format('woff');
}

@font-face {
  font-family: 'J&J CircularTT Light Web';
  src: url('common/assets/fonts/J&J CircularTT-Light.eot');
  src: url('common/assets/fonts/J&J CircularTT-Light.woff2') format('woff2'),
    url('common/assets/fonts/J&J CircularTT-Light.woff') format('woff');
}

@font-face {
  font-family: 'J&J CircularTT Light Italic Web';
  src: url('common/assets/fonts/J&J CircularTT-LightItalic.eot');
  src: url('common/assets/fonts/J&J CircularTT-LightItalic.woff2') format('woff2'),
    url('common/assets/fonts/J&J CircularTT-LightItalic.woff') format('woff');
}

@font-face {
  font-family: 'J&J CircularTT Medium Web';
  src: url('common/assets/fonts/J&J CircularTT-Medium.eot');
  src: url('common/assets/fonts/J&J CircularTT-Medium.woff2') format('woff2'),
    url('common/assets/fonts/J&J CircularTT-Medium.woff') format('woff');
}

@font-face {
  font-family: 'J&J CircularTT Medium Italic Web';
  src: url('common/assets/fonts/J&J CircularTT-MediumItalic.eot');
  src: url('common/assets/fonts/J&J CircularTT-MediumItalic.woff2') format('woff2'),
    url('common/assets/fonts/J&J CircularTT-MediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'J&J CircularTT Black Web';
  src: url('common/assets/fonts/J&J CircularTT-Black.eot');
  src: url('common/assets/fonts/J&J CircularTT-Black.woff') format('woff'),
    url('common/assets/fonts/J&J CircularTT-Black.woff2') format('woff2');
}

@font-face {
  font-family: 'JohnsonDisplay-Bold';
  src: url('common/assets/fonts/JohnsonDisplay-Bold.otf');
  src: url('common/assets/fonts/JohnsonDisplay-Bold.otf') format('otf'),
    url('common/assets/fonts/JohnsonDisplay-Bold.otf') format('otf');
}
 
@font-face {
  font-family: 'JohnsonText-Bold';
  src: url('common/assets/fonts/JohnsonText-Bold.otf');
  src: url('common/assets/fonts/JohnsonText-Bold.otf') format('otf'),
    url('common/assets/fonts/JohnsonText-Bold.otf') format('otf');
}
 
@font-face {
  font-family: 'JohnsonText-Light';
  src: url('common/assets/fonts/JohnsonText-Light.otf');
  src: url('common/assets/fonts/JohnsonText-Light.otf') format('otf'),
    url('common/assets/fonts/JohnsonText-Light.otf') format('otf');
}
 

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

html,
body {
  height: 100%;
}
body,
#root {
  display: flex;
  flex-direction: column;
}

#root {
  flex: 1 0 auto;
}

.gradient-border {
  --borderWidth: 3px;
  border-radius: var(--borderWidth);
  position: relative;
  background: #fff;
}
.gradient-border:after {
  content: '';
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(
    60deg,
    #f79533,
    #f37055,
    #ef4e7b,
    #a166ab,
    #5073b8,
    #1098ad,
    #07b39b,
    #6fba82
  );
  border-radius: calc(2 * var(--borderWidth));
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes blink {
  /**
     * At the start of the animation the dot
     * has an opacity of .2
     */
  0% {
    opacity: 0.2;
  }
  /**
     * At 20% the dot is fully visible and
     * then fades out slowly
     */
  20% {
    opacity: 1;
  }
  /**
     * Until it reaches an opacity of .2 and
     * the animation can start again
     */
  100% {
    opacity: 0.2;
  }
}

.refresh span {
  /**
     * Use the blink animation, which is defined above
     */
  animation-name: blink;
  /**
     * The animation should take 1.4 seconds
     */
  animation-duration: 1.4s;
  /**
     * It will repeat itself forever
     */
  animation-iteration-count: infinite;
  /**
     * This makes sure that the starting style (opacity: .2)
     * of the animation is applied before the animation starts.
     * Otherwise we would see a short flash or would have
     * to set the default styling of the dots to the same
     * as the animation. Same applies for the ending styles.
     */
  animation-fill-mode: both;
}

.refresh span:nth-child(2) {
  /**
     * Starts the animation of the third dot
     * with a delay of .2s, otherwise all dots
     * would animate at the same time
     */
  animation-delay: 0.2s;
}

.refresh span:nth-child(3) {
  /**
     * Starts the animation of the third dot
     * with a delay of .4s, otherwise all dots
     * would animate at the same time
     */
  animation-delay: 0.4s;
}
