/* ------------------------------------------------- */
/* -------------MANAGER MANY TEAMS TABLE------------- */
/* ------src\TAP\pages\managerManyTeamsView.js------ */

.manager-many-teams-table-row-light {
  background-color: #ffffff;
}

.manager-many-teams-table-row-dark {
  background: rgba(0, 0, 153, 0.03);
}
.manager-many-teams-table-row-disabled {
  background: rgba(33, 33, 33, 0.1);
}
.manager-many-teams-table-row-disabled:hover > td {
  background: transparent !important;
}

td.ant-table-cell svg + div {
  display: inline-block;
  padding-left: 30px;
}

td.ant-table-cell span:not(:has(+ svg)) + div {
  display: inline-block;
  padding-left: 60px;
}

tr.ant-table-expanded-row > td.ant-table-cell:empty {
  display: none;
}

/* ----------------------------------------------------- */
/* -------INDIVIDUAL USER TRAINING PLAN VIEW TABLE------ */
/* -----src\TAP\pages\trainingViewIndividualUser.js----- */

/* .training-view-curriculum-column-header {
  font-family: 'J&J CircularTT Black Web';
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
} */

.training-view-curriculum-title-box {
  cursor: pointer;
}

/* .training-view-curriculum-title {
  font-family: 'J&J CircularTT Book Web';
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #333333;
} */

.training-view-no-data-overlay {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
  background: #f4f4f4;
  font-weight: 900;
  font-size: 26px;
  font-family: 'JohnsonDisplay-Bold', sans-serif;
  line-height: 32px;
  color: #888b8d;
  width: 100%;
  /* height: 20rem; */
  height: 30rem;
}

.training-view-individual-user-parent-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: -12px;
}

/* .training-view-individual-user-page-heading {
  font-size: 32px;
} */

.training-view-individual-user-search-box-parent {
  display: flex;
  margin-bottom: 20px;
  flex-direction: row;
  width: 100%;
}

.training-view-individual-user-curriculum-search-box {
  display: flex;
  width: 39%;
}

.training-view-individual-user-curriculum-autocomplete {
  margin-right: 8px;
  width: 100%;
}

/* .training-view-individual-user-curriculum-textfield {
  border-bottom: 2px solid black;
  font-family: 'J&J CircularTT Black Web';
  font-weight: 450;
  font-size: 14px;
  line-height: 16px;
} */

.training-view-individual-user-training-search-box {
  display: flex;
  width: 39%;
  align-items: flex-end;
}

.training-view-individual-user-training-autocomplete {
  margin-left: 8px;
  width: 100%;
}

.tv-ind-user-download-wrapper {
  width: 22%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.tv-ind-user-download-btn {
  background-color: #ca001b;
  color: #ffffff;
  padding: 9px 30px;
  border-radius: 2px;
  gap: 10px;
  text-transform: none;
  font-weight: 700;
  font-size: 16px;
  width: 250px;
  height: 38px;
  margin-bottom: 8px;
  font-family: 'JohnsonText-Medium', sans-serif;
}

.tv-ind-user-download-btn:hover {
  background-color: #ca001b;
  color: #ffffff;
}

.tv-ind-user-download-btn:focus {
  outline: none;
}

/* .training-view-individual-user-training-textfield {
  border-bottom: 2px solid black;
  font-family: 'J&J CircularTT Black Web';
  font-weight: 450;
  font-size: 14px;
  line-height: 16px;
} */

.training-view-individual-user-table-parent-box {
  padding: 10px 0;
}

.training-view-individual-user-table .ant-table-thead > tr > th,
.my-teams-perf-nested-table .ant-table-thead > tr > td,
.my-teams-perf-nested-table .ant-table-thead > tr > th {
  background-color: #ffffff !important;
}

@media screen and (min-width: 435px) and (max-width: 1024px) {
  .my-teams-perf-nested-table .ant-spin-nested-loading {
    position: relative;
    width: 691px !important;
    margin-left: 0px !important;
  }
  .my-teams-perf-nested-table .ant-spin-nested-loading .ant-table {
    overflow-x: scroll !important;
  }
}
@media screen and (max-width: 430px) {
  .my-teams-perf-nested-table .ant-spin-nested-loading {
    position: relative;
    margin-left: 12px !important;
    margin-right: 10px !important;
  }
  .my-teams-perf-nested-table .ant-spin-nested-loading .ant-table {
    overflow-x: scroll !important;
  }
  .ant-pagination .ant-pagination-options {
    display: revert !important;
  }
}

.training-view-individual-user-table-row-light > td.ant-table-column-sort {
  background-color: #ffffff !important;
}

.training-view-individual-user-table-row-dark > td.ant-table-column-sort {
  background: rgba(0, 0, 153, 0.03) !important;
}

/** Training PLan View DataGrid CSS */
.tpv-st-table-row-light {
  background-color: #ffffff;
}

.tpv-st-table-row-dark {
  background: rgba(0, 0, 153, 0.03);
}

.ant-table-wrapper.nested-training-viewplan-datagrid .ant-table-tbody > tr > td {
  padding-left: 44% !important;
}

.ant-table-wrapper.training-view-plan-singleteam-datagrid tr > td,
.ant-table-wrapper.training-view-plan-singleteam-datagrid .ant-table-thead > tr > th {
  border-bottom: none !important;
}

.ant-table-wrapper.training-view-plan-singleteam-datagrid .ant-table-thead > tr > th,
.ant-table-wrapper.training-view-plan-singleteam-datagrid .ant-table-thead > tr > td {
  background: #fff !important;
}

.ant-table-wrapper.training-view-plan-singleteam-datagrid
  tr.ant-table-expanded-row
  > td.ant-table-cell {
  background: #fff !important;
  padding-left: 0 !important;
}

.ant-table-wrapper.nested-training-viewplan-datagrid .ant-table {
  margin-inline-start: 0px !important;
}

.ant-table-wrapper.nested-training-viewplan-datagrid .ant-table-thead {
  display: none !important;
}

.ant-table-wrapper.training-view-plan-singleteam-datagrid td.ant-table-column-sort,
.ant-table-wrapper.nested-training-viewplan-datagrid td.ant-table-column-sort {
  background-color: rgba(0, 0, 153, 0.03) !important;
}

.ant-table-wrapper.training-view-plan-singleteam-datagrid
  .ant-table-tbody
  > tr.ant-table-row:hover
  > td,
.ant-table-wrapper.nested-training-viewplan-datagrid
  .ant-table-tbody
  > tr.ant-table-row:hover
  > td {
  background: rgb(0 0 0 / 4%);
}

.ant-table-wrapper.training-view-plan-singleteam-datagrid
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
    [colspan]
  )::before {
  content: none !important;
}

.ant-table-wrapper.training-view-plan-singleteam-datagrid
  tr
  > td.ant-table-cell.training-planview-columns-dr {
  vertical-align: top !important;
}

@media only screen and (min-width: 1920px) {
  .ant-table-wrapper.nested-training-viewplan-datagrid .ant-table-tbody > tr > td {
    padding-left: 42.5% !important;
  }
}

@media only screen and (min-width: 1366px) {
  .ant-table-wrapper.nested-training-viewplan-datagrid .ant-table-tbody > tr > td {
    padding-left: 43.8% !important;
  }
}

@media only screen and (min-width: 1440px) {
  .ant-table-wrapper.nested-training-viewplan-datagrid .ant-table-tbody > tr > td {
    padding-left: 43.8% !important;
  }
}

@media only screen and (min-width: 1536px) {
  .ant-table-wrapper.nested-training-viewplan-datagrid .ant-table-tbody > tr > td {
    padding-left: 43.3% !important;
  }
}

.ant-pagination .ant-pagination-total-text,
.ant-pagination .ant-pagination-item a,
.ant-select-item.ant-select-item-option,
.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  font-family: 'JohnsonText-Medium', sans-serif !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  font-style: normal !important;
  color: #212121 !important;
}

/* ------------------------------------------------- */
/* -------------PAGINATIONS STYLE------------- */

.MuiTablePagination-menuItem {
  font-family: 'JohnsonText-Medium', sans-serif !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 20px !important;
  font-style: normal !important;
  color: #212121 !important;
}

.MuiMenu-paper {
  border-radius: 12px !important;
  left: 20px;
}

/* ------------------------------------------------- */
/* -------------GLOBAL NAVIGATION MENU TABS STYLE------------- */

.MuiTabs-flexContainer .MuiTab-root {
  font-family: 'JohnsonText-Medium', sans-serif !important;
  color: '#333333';
  font-size: '16px' !important;
  font-weight: 400;
}
/* Mui menu item for mobile */
@media screen and (max-width: 420px) {
  .css-6hp17o-MuiList-root-MuiMenu-list,
  .css-r8u8y9,
  .MuiList-padding {
    max-height: 100vw;
    overflow: auto;
    max-width: 85vw;
  }
}
/* date picker alignment for both mobile and iphone view */
@media screen and (max-width: 430px) {
  .ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
    display: inline-block;
    flex-wrap: nowrap;
    direction: ltr;
    height: 400px;
    overflow-y: auto;
  }

  .ant-picker-panel-container {
    width: 300px;
  }
}

@media screen and (min-width: 430px) and (max-width: 768px) {
  .trainingBarChartSvg {
    width: 312px !important;
    height: 216px !important;
    margin-left: -17px !important;
  }
  .ComplianceBarChartSvg {
    width: 330px !important;
    margin-left: -15px !important;
  }
}

/* datepicker size for tap view  */
@media screen and (min-width: 420px) and (max-width: 820px) {
  .ant-picker-range {
    width: 260px;
    padding: 4px 8px !important;
  }
}

/* Media queries for Tablet  */
/* iPad mini */
@media only screen and (min-device-width: 768px) and (max-device-width: 819px) {
  .download-report-graph-tile-trainings-completed-on-time {
    top: -7.3vh !important;
  }
}
/* iPad air */
@media only screen and (min-device-width: 820px) and (max-device-width: 1023px) {
  .download-report-graph-tile-trainings-completed-on-time {
    top: -6.8vh !important;
  }
}
/* iPad pro */
@media only screen and (min-device-width: 821px) and (max-device-width: 1024px) {
  .download-report-graph-tile-overall-compliance-profile {
    top: -2.1vh !important;
  }
}
